import React, {useEffect, useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useHistory} from 'react-router-dom'
import {ITemplate} from "../types";
import ApiRest from "../../service/ApiRest";
import {NaeTable} from "nae-react-core-styles";

const moduleName = 'template'

export default function TemplatesListPage() {
    const history = useHistory();

    const [readyToLoad, setReadyToLoad] = useState(false);

    const [templates, setTemplates] = useState<ITemplate[]>([])
    const [dataToRender, setDataToRender] = useState<ITemplate[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState('');

    const [filter, setFilter] = useState('');

    const getData = () => {
        ApiRest.getElements(moduleName).then(res => {
            setTemplates(res);
        })
    }

    useEffect(getData, []);

    const filterData = () => {
        if (readyToLoad) {
            let _data = templates;
            if (search) {
                _data = _data.filter((item: ITemplate) => {
                    return item.subject.toLowerCase().indexOf(search.toLowerCase()) > -1
                });
            }


            if (filter) {

            }

            setDataToRender(_data);
        }
    }

    useEffect(filterData, [search, activePage, templates, filter, readyToLoad]);

    const restoreHistoryState = () => {
        const params = ['search', 'activePage', 'filter']
        // @ts-ignore
        if (history && history.location && history.location.state) {
            params.map(p => {
                // @ts-ignore
                const v = history.location.state[p];

                if (p === 'search') {
                    setSearch(v);
                } else if (p === 'activePage') {
                    setActivePage(v);
                } else if (p === 'filter') {
                    setFilter(v);
                }
                return p;
            })
        }
        setReadyToLoad(true);
    }
    useEffect(restoreHistoryState, []);

    useEffect(() => {
        if (readyToLoad) {
            setActivePage(1);
        }
    }, [search, filter]);

    const goTo = (item: ITemplate) => {
        history.replace('/templates', {search, activePage, filter});
        history.push('/' + moduleName + '/' + item.id);
    }

    const goToNew = () => {
        history.push('/' + moduleName + '/new');
    }

    return <Container>
        <Row>
            <Col>
                <Card className={"card-table"}>
                    <Card.Header>
                        <Row>
                            <Col className={"v-center"}>
                                Templates
                            </Col>
                            <Col className={"text-right"}>
                                <Button variant={"success"} onClick={goToNew}>New</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Header className={"search"}>
                        <Row>
                            <Col className={"v-center"}>
                                <input placeholder={"Search"} className={"search-input"} value={search}
                                       onChange={(e) => setSearch(e.target.value)}/>
                            </Col>
                            <Col sm={2}>
                                <select value={filter} onChange={e => setFilter(e.target.value)}>
                                    <option value={""}>Filter</option>
                                </select>
                            </Col>

                        </Row>
                    </Card.Header>
                    <NaeTable.TableWithPaging
                        activePage={activePage}
                        setActivePage={setActivePage}
                        dataToRender={dataToRender} head={
                        <tr>
                            <NaeTable.th id={true}>ID</NaeTable.th>
                            <NaeTable.th>Subject</NaeTable.th>
                            <NaeTable.th>Status</NaeTable.th>

                        </tr>
                    } renderItem={(item: ITemplate) => {
                        return (
                            <tr onClick={() => goTo(item)} key={"list-row-" + item.id}>
                                <NaeTable.td id={true}>{item.id}</NaeTable.td>
                                <NaeTable.td link={true}>{item.subject}</NaeTable.td>
                                <NaeTable.td>{item.active ? 'active' : 'inactive'}</NaeTable.td>
                            </tr>
                        )
                    }}
                    />

                </Card>
            </Col>
        </Row>
    </Container>
}
