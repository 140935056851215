import React, {Fragment, useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
// import useLocalStorage from "../../Hooks/useLocalStorage";
// import ApiProfile from "../../service/ApiProfile";
import {NaeLayout, NaeNavBar} from 'nae-react-core-styles'
import {NaeAuthLogoutBtn} from 'nae-react-auth'
import {Nav} from "react-bootstrap";
import {useLocalStorage} from "nae-react-hooks";
import ApiProfile from "../../service/ApiProfile";
import {IUser, userDefElement} from "../types";


interface ProviderValue {
    user: IUser
}

export const UserContext = React.createContext<ProviderValue>({
    user: userDefElement
})
export const useUser = () => useContext(UserContext);

interface Props {
    children: any,
}

export const UserProvider = (props: Props) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(userDefElement);
    const [token] = useLocalStorage('token', '');
    const history = useHistory();

    const navigate = (e: any, route: string) => {
        e.preventDefault();
        history.push('/' + route);
    }

    const getData = () => {
        ApiProfile.getProfile().then(res => {
            if (!!res.isError) {
                history.push('/login');
                return;
            }
            setUser(res);
            setLoaded(true);
        }).catch(e => {
            history.push('/login');
        })
    }
    useEffect(getData, [token, history]);

    if (!loaded) {
        return <Fragment/>
    }

    return (
        <UserContext.Provider value={{
            user
        }}>
            {!!user.email ? <NaeLayout.NaePageWrapper top={<NaeNavBar.NaeTopNavbar middleComponent={
                <Nav>
                    <Nav.Link href={'/templates'} onClick={(e: any) => navigate(e, 'templates')}>Templates</Nav.Link>

                    {/*<Nav.Link href={'/recipients'} onClick={(e: any) => navigate(e, 'recipients')}>Recipients</Nav.Link>*/}

                    <Nav.Link href={'/auth-keys'} onClick={(e: any) => navigate(e, 'auth-keys')}>Auth keys</Nav.Link>

                    <Nav.Link href={'/profile'} onClick={(e: any) => navigate(e, 'profile')}>Profile</Nav.Link>

                </Nav>
            } rightComponent={<NaeAuthLogoutBtn/>}/>}>
                {props.children}
            </NaeLayout.NaePageWrapper> : <Redirect to={"/login"}/>}
        </UserContext.Provider>

    )
}
