class ApiProfileService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/profile';
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    authUploadRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Authorization': token
            },
        }
    };
    getProfile = () => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(this.baseUrl + '/get', requestOptions).then(res => res.json());
    }

    saveProfile = (email: string,
                   password: string,
                   passwordRepeat: string,
                   smtpHost: string,
                   smtpUsername: string,
                   smtpPassword: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({email, password, passwordRepeat, smtpHost, smtpUsername, smtpPassword})
        };

        return fetch(this.baseUrl + '/save', requestOptions).then(res => res.json());
    }

    testEmail = (subject: string,
                 html: string,
                 fromName: string,
                 fromEmail: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({subject, html, fromName, fromEmail})
        };

        return fetch(this.baseUrl + '/testEmail', requestOptions).then(res => res.json());
    }
}

const ApiProfile = new ApiProfileService();
export default ApiProfile;
