import React, {Fragment} from 'react';
import 'nae-react-core-styles/dist/index.css';
import {NaeApiAuth, NaeAuthLoginPage, NaeAuthPasswordRemindPage, NaeAuthRegisterPage} from 'nae-react-auth'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {UserProvider} from "./Components/User/UserProvider";
import AuthKeysListPage from "./Components/Pages/AuthKeysListPage";
import TemplatesListPage from "./Components/Pages/TemplatesListPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TemplatesPage from "./Components/Pages/TemplatesPage";
import TemplateHtmlPage from "./Components/Pages/TemplatesHtmlPage";
import UserProfilePage from "./Components/User/UserProfilePage";
import AuthKeysPage from "./Components/Pages/AuthKeysPage";

// @ts-ignore
NaeApiAuth.baseUrl = '/app/auth';

function App() {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route path={"/login"}><NaeAuthLoginPage/></Route>
                    <Route path={"/register"}><NaeAuthRegisterPage/></Route>
                    <Route path={"/password-remind"}><NaeAuthPasswordRemindPage/></Route>

                    <UserProvider>
                        <Fragment>
                            <Route path={"/templates"}><TemplatesListPage/></Route>
                            <Route path={"/template/:id"}><TemplatesPage/></Route>
                            <Route path={"/template-html/:id"}><TemplateHtmlPage/></Route>

                            <Route path={"/auth-keys"}><AuthKeysListPage/></Route>
                            <Route path={"/auth-key/:id"}><AuthKeysPage/></Route>

                            <Route path={"/profile"}><UserProfilePage/></Route>

                        </Fragment>
                    </UserProvider>
                </Switch>
            </Router>
            <ToastContainer/>
        </Fragment>
    );
}

export default App;
