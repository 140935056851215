export interface ITemplate {
    id: number,
    active: boolean,
    subject: string,
    template: any,
    html: string,

    fromName: string,
    fromEmail: string,
    replyTo: string,

    dataSource: string,
}


const templateDefElement: ITemplate = {
    id: 0,
    active: false,
    subject: '',
    template: {},
    html: '',
    replyTo: '',
    fromEmail: '',
    fromName: '',
    dataSource: '',
}


export interface IAuthKey {
    id: number,
    key: string,
    title: string,
    createdAt: string,
}

const authKeyDefElement: IAuthKey = {
    id: 0,
    key: '',
    title: '',
    createdAt: '',
}

export interface IUser {
    id: number,
    email: string,
    smtpHost: string,
    smtpUsername: string,
    smtpPassword: string
}

const userDefElement: IUser = {
    id: 0,
    email: '',
    smtpHost: '',
    smtpPassword: '',
    smtpUsername: '',
}

export interface IRecpient {
    id: number,
    email: string,
    createdAt: string,
    template: string,
    templateId: number,
}

export {templateDefElement, userDefElement, authKeyDefElement};
