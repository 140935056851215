import {NaeRouter} from "nae-react-core-styles";
import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {IAuthKey} from "../types";


interface Props {
    element: IAuthKey,
    saveData: (title: string,
    ) => void,
}

export default function AuthKeysForm(props: Props) {

    const {element, saveData} = props;
    const [title, setTitle] = useState(element.title);


    return (
        <Card>
            <Card.Header>
                <NaeRouter.BackBtn/>
                Auth key
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control value={title} onChange={e => setTitle(e.target.value)}/>
                    </Form.Group>
                    {element.id > 0 &&
                    <Form.Group>
                        <Form.Label>Key</Form.Label>
                        <Form.Text>{element.key}</Form.Text>
                    </Form.Group>}
                    {element.id > 0 &&
                    <Form.Group>
                        <Form.Label>Created</Form.Label>
                        <Form.Text>{element.createdAt}</Form.Text>
                    </Form.Group>}
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    title
                                )}>Save</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
