import React, {useState} from "react";
import {Button, Card, Container, Form} from "react-bootstrap";
import {useUser} from "./UserProvider";

import {NaeLayout} from 'nae-react-core-styles'
import ApiProfile from "../../service/ApiProfile";

export default function UserProfilePage() {
    const {user} = useUser();
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [smtpHost, setSmtpHost] = useState(user.smtpHost);
    const [smtpUsername, setSmtpUsername] = useState(user.smtpUsername);
    const [smtpPassword, setSmtpPassword] = useState(user.smtpPassword);

    const doSave = () => {
        ApiProfile.saveProfile(email, password, passwordRepeat, smtpHost, smtpUsername, smtpPassword).then(res => {
            if (!!res.isError) {
                alert(res.error.description);
                return;
            }
        })
    }

    return (
        <Container>

            <NaeLayout.NaeCenterColumn margins={3}>
                <Card>
                    <Card.Header>Profile</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>
                                    E-mail
                                </Form.Label>

                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)}
                                              autoComplete="nope"/>

                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    SMTP host
                                </Form.Label>

                                <Form.Control value={smtpHost}
                                              onChange={(e) => setSmtpHost(e.target.value)} autoComplete="nope"/>

                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    SMTP user
                                </Form.Label>

                                <Form.Control value={smtpUsername}
                                              onChange={(e) => setSmtpUsername(e.target.value)} autoComplete="nope"/>

                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    SMTP password
                                </Form.Label>

                                <Form.Control value={smtpPassword}
                                              onChange={(e) => setSmtpPassword(e.target.value)} autoComplete="nope"/>

                            </Form.Group>


                            <Form.Group>
                                <Form.Label>
                                    Password
                                </Form.Label>

                                <Form.Control type={"password"} value={password}
                                              onChange={(e) => setPassword(e.target.value)} autoComplete="nope"/>

                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Repeat password
                                </Form.Label>

                                <Form.Control type={"password"} value={passwordRepeat}
                                              onChange={(e) => setPasswordRepeat(e.target.value)} autoComplete="nope"/>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                    <Card.Footer className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => doSave()}>Save</Button>
                    </Card.Footer>
                </Card>
            </NaeLayout.NaeCenterColumn>

        </Container>
    )
}
