import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {toast} from "react-toastify";
import ApiRest from "../../service/ApiRest";
import {Card} from "react-bootstrap";
import {authKeyDefElement} from "../types";
import AuthKeysForm from "../Forms/AuthKeysForm";
import { NaeRestRemoveBtn } from "nae-react-rest";

const moduleName = 'auth-key';

interface ParamTypes {
    id: string
}

export default function AuthKeysPage() {
    const history = useHistory();

    const [element, setElement] = useState(authKeyDefElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (title: string) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    title
                }
            ).then(res => {
                toast.success('Element saved');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    title
                }
            ).then(res => {
                toast.success('Element saved');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <AuthKeysForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        <Card>
            <Card.Body>
                <NaeRestRemoveBtn lang={"en"} path={'/auth-keys'} module={moduleName} id={element.id}/>
            </Card.Body>
        </Card>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

        </Fragment>
    )
}
