import React, {Fragment, useEffect, useRef, useState} from 'react';
import EmailEditor, {HtmlExport} from "react-email-editor";
import ApiRest from "../../service/ApiRest";
import {useParams} from "react-router-dom";
import {templateDefElement} from "../types";
import {Button, Col, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import ApiProfile from "../../service/ApiProfile";

const moduleName = 'template';

interface ParamTypes {
    id: string
}

export default function TemplateHtmlPage() {
    const emailEditorRef = useRef(null);
    const [element, setElement] = useState(templateDefElement);
    const {id} = useParams<ParamTypes>();
    const [isLoaded, setIsLoaded] = useState(false);

    const exportHtml = () => {
        if (emailEditorRef && emailEditorRef.current) {
            // @ts-ignore
            emailEditorRef.current.editor.exportHtml((data: HtmlExport) => {
                const {html, design} = data;

                ApiRest.updateElement(
                    moduleName,
                    element.id,
                    {
                        template: design,
                        html
                    }
                ).then(res => {
                    toast.success('Element saved');
                    setElement(res);
                });

            });
        }
    };

    const testEmail = () => {
        if (emailEditorRef && emailEditorRef.current) {
            // @ts-ignore
            emailEditorRef.current.editor.exportHtml((data: HtmlExport) => {
                ApiProfile.testEmail(element.subject, data.html, element.fromName, element.fromEmail).then(res => {
                    toast.success('Sent success');
                })
            });
        }
    }

    const onLoad = () => {
        if (isLoaded &&
            emailEditorRef &&
            emailEditorRef.current &&
            Object.prototype.toString.call(element.template) !== '[object Array]' &&
            element.id > 0
        ) {
            // @ts-ignore
            emailEditorRef.current.editor.loadDesign(element.template);
        }
    };

    useEffect(onLoad, [emailEditorRef, element, isLoaded])

    const getData = () => {
        ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
            setElement(res);
        }).catch(e => {

        });
    }
    useEffect(getData, [id]);

    if (!element || !element.id) {
        return <Fragment/>
    }


    return <Fragment>
        <Row>
            <Col>
                <Button variant={"info"} onClick={testEmail}>Send test email</Button>
            </Col>
            <Col className={"text-right"}>
                <Button variant={"success"} onClick={exportHtml}>Save</Button>
            </Col>

        </Row>
        <div className={"editor-wrapper"}>
            <EmailEditor
                ref={emailEditorRef}
                onLoad={() => setIsLoaded(true)}
            />
        </div>
    </Fragment>
}
