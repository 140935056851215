import { NaeRouter } from "nae-react-core-styles";
import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {ITemplate} from "../types";


interface Props {
    element: ITemplate,
    saveData: (subject: string,
               active: boolean,
               fromName: string,
               fromEmail: string,
               replyTo: string,
               dataSource: string,
    ) => void,
}

export default function TemplatesForm(props: Props) {

    const {element, saveData} = props;
    const [subject, setSubject] = useState(element.subject);
    const [active, setActive] = useState(element.active);

    const [fromName, setFromName] = useState(element.fromName);
    const [fromEmail, setFromEmail] = useState(element.fromEmail);
    const [replyTo, setReplyTo] = useState(element.replyTo);

    const [dataSource, setDataSource] = useState(element.dataSource);


    return (
        <Card>
            <Card.Header>
                <NaeRouter.BackBtn/>
                Template
            </Card.Header>
            <Card.Body>
                <Form>
                    {element.id > 0 &&
                    <Form.Group>
                        <Form.Label>ID</Form.Label>
                        <Form.Text>{element.id}</Form.Text>
                    </Form.Group>}
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control value={subject} onChange={e => setSubject(e.target.value)}/>
                    </Form.Group>

                    <Form.Group onClick={() => setActive(!active)}>
                        <Form.Check type="checkbox" label="Active" checked={active} onChange={() => {
                        }}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>From name</Form.Label>
                        <Form.Control value={fromName} onChange={e => setFromName(e.target.value)}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>From email</Form.Label>
                        <Form.Control value={fromEmail} onChange={e => setFromEmail(e.target.value)}/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Reply to</Form.Label>
                        <Form.Control value={replyTo} onChange={e => setReplyTo(e.target.value)}/>
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Data source</Form.Label>
                        <Form.Control value={dataSource} onChange={e => setDataSource(e.target.value)}/>
                    </Form.Group>
                </Form>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>

                    </Col>
                    <Col className={"text-right"}>
                        <Button type={"button"} variant={"primary"}
                                onClick={() => saveData(
                                    subject,
                                    active,
                                    fromName,
                                    fromEmail,
                                    replyTo,
                                    dataSource
                                )}>Save</Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>)
}
