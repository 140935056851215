import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from 'react-router-dom'
import {NaeLayout} from 'nae-react-core-styles'
import {toast} from "react-toastify";
import ApiRest from "../../service/ApiRest";
import TemplatesForm from "../Forms/TemplatesForm";
import {Button, Card} from "react-bootstrap";
import {templateDefElement} from "../types";

const moduleName = 'template';

interface ParamTypes {
    id: string
}

export default function TemplatesPage() {
    const history = useHistory();

    const [element, setElement] = useState(templateDefElement);
    const {id} = useParams<ParamTypes>();
    const saveData = (subject: string,
                      active: boolean,
                      fromName: string,
                      fromEmail: string,
                      replyTo: string,
                      dataSource: string,
    ) => {
        if (id === 'new') {
            ApiRest.addElement(
                moduleName,
                {
                    subject,
                    active,
                    fromName,
                    fromEmail,
                    replyTo,
                    dataSource
                }
            ).then(res => {
                toast.success('Element saved');
                setElement(res);
                history.push('/' + moduleName + '/' + res.id);
            })
        } else {
            ApiRest.updateElement(
                moduleName,
                element.id,
                {
                    subject,
                    active,
                    fromName,
                    fromEmail,
                    replyTo,
                    dataSource
                }
            ).then(res => {
                toast.success('Element saved');
                setElement(res);
            });
        }
    }

    const getData = () => {
        if (id !== 'new') {
            ApiRest.getElement(moduleName, parseInt(id, 10)).then(res => {
                setElement(res);
            }).catch(e => {

            });
        }
    }

    const loadTemplate = () => {
        history.push('/template-html/' + element.id);
    }

    useEffect(getData, [id]);

    const form = (element.id > 0 || id === 'new') ?
        <Fragment>
            <TemplatesForm element={element} saveData={saveData}/>
        </Fragment>
        : <Fragment/>
    ;

    const rightComponent = (
        element.id > 0 ?
            <Card>
                <Card.Body>
                    <Button variant={"outline-success"} onClick={loadTemplate}>HTML template</Button>
                </Card.Body>
            </Card>
            : <Fragment/>
    );

    return (<Fragment>

            <NaeLayout.NaePageViewLayout leftComponent={form} rightComponent={rightComponent}/>

        </Fragment>
    )
}
